.landing__title {
  text-align: center;
  margin-bottom: 2px;
  margin-top: 0;
  font-size: 20px;
}

.landing__subtitle {
  text-align: center;
  font-size: 16px;
  margin-bottom: 24px;
  margin-top: 4px;
}

.play-now-btn {
  font-family: "Oswald", sans-serif;
  color: #fff;
  font-size: 18px;
  border: 2px solid #6c6969;
  padding: 6px 14px;
  border-radius: 4px;
  animation: breathing-glow 1s ease-out alternate infinite;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: 155px;
  min-height: 37.5px;
  background-color: var(--green-team-color);
  box-shadow: 0 0 4px #171818, 0 0 8px #171818, 0 0 12px #171818;
}

.play-now-btn:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.highlight-box {
  padding: 6px 16px 18px 16px;
  background-color: antiquewhite;
  color: var(--font-color);
  text-align: left;
  margin: 20px auto 0 auto;
  max-width: 350px;
  border-radius: 4px;
}

.highlight-box ol {
  margin-top: 4px;
  padding-left: 10px;
}

.highlight-box h4 {
  margin-top: 4px;
  margin-bottom: 8px;
}

.highlight-box h4 {
  text-align: center;
}

.manual li {
  font-size: 14px;
  list-style: disc;
  font-weight: lighter;
  margin-bottom: 8px;
}

em {
  display: flex;
  justify-content: center;
}

.landing__logo {
  margin: 0 auto;
  display: block;
  max-width: 60px;
}

@media screen and (max-width: 380px) {
  .landing__title {
    font-size: 18px;
  }

  .landing__subtitle {
    font-size: 14px;
  }

  .play-now-btn {
    font-size: 16px;
    min-width: 135px;
    min-height: 32.5px;
  }

  .highlight-box {
    padding: 6px 12px 16px 12px;
  }

  .highlight-box h4 {
    font-size: 16px;
  }

  .manual li {
    font-size: 12px;
  }
}
