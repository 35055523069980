/* Modal overlay: covers the screen with a semi-transparent background */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: #000;
}

/* Modal content: ensure it doesn't absorb the full height */
.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-out;
  padding: 1rem;
}

/* Ensure modal-content doesn't capture clicks meant for the background */
.modal-content {
  pointer-events: auto;
}

/* Header styling: title and close button */
.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
}

/* Close button styling */
.icon-btn.close-modal-btn {
  background: transparent;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.25rem;
  transition: color 0.2s ease;
}

.icon-btn.close-modal-btn:hover {
  color: #ff0000;
}

/* Modal body: space for input and labels */
.modal-body {
  padding: 1rem;
  text-align: left;
}

.modal-body label {
  display: inline;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.modal-body input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

/* Modal footer: for action buttons */
.modal-footer {
  padding: 1rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

/* Button styles: common for all buttons */
.btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn:hover {
  background-color: #0056b3;
}

.btn--cancel {
  background-color: #dc3545;
}

.btn--cancel:hover {
  background-color: #c82333;
}

.room-code-input {
  text-transform: uppercase;
}
