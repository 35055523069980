@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap");

body {
  background-color: #272f38;
  color: white;
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif;
}

* {
  box-sizing: border-box;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

button {
  position: relative;
  z-index: 5;
  background-color: transparent;
  font-family: "Oswald", sans-serif;
}

:root {
  /* Team Colors */
  --red-team-color: #9f3c39; /* A deep, rich red */
  --blue-team-color: #4897af; /* A strong, classic blue */
  --green-team-color: #25a95c; /* A vibrant, fresh green */

  /* Font Colors for Team Cards */
  --red-team-font-color: #ffffff; /* White for good contrast */
  --blue-team-font-color: #ffffff; /* White for good contrast */
  --green-team-font-color: #ffffff; /* White for good contrast */

  /* Neutral Cards */
  --neutral-card-color: #feebda; /* A softer, more elegant grey */
  --neutral-card-font-color: black;

  /* Unrevealed Cards */
  --unrevealed-card-color: #dcdde1; /* A lighter, more refined grey */
  --unrevealed-card-font-color: #2c3e50; /*

  /* Board Background */
  --board-background-color: #727e8a; /* A deep, muted blue for a sophisticated look */

  /* Button Colors */
  --button-color: #e67e22; /* A warm, inviting orange */
  --button-font-color: #ffffff; /* White for clarity and contrast */

  /* Common */
  --font-color: #2c3e50; /* A versatile dark shade for general text */
}

/* utils */

.mr--2 {
  margin-right: 2px;
}

.mr--4 {
  margin-right: 4px;
}

.mr--8 {
  margin-right: 8px;
}

.mt--1 {
  margin-top: 1px;
}

.mt--2 {
  margin-top: 2px;
}

.mt--6 {
  margin-top: 14px;
}

.mt--10 {
  margin-top: 20px;
}

.mb--4 {
  margin-bottom: 6px;
}

.mb--6 {
  margin-bottom: 14px;
}

.mb--10 {
  margin-bottom: 20px;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.icon-link {
  color: #fff;
  text-decoration: none;
}

.font--light {
  font-weight: 300;
}

.text-center {
  text-align: center;
}
