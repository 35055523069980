.home {
  text-align: center;
  height: 100dvh;
  background-color: #272f38;
}

.home-welcome {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-room-btn {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--green-team-color);
  color: #fff;
  border: 3px solid var(--green-team-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  box-shadow: 0 0 4px #171818, 0 0 8px #171818, 0 0 12px #171818;
}

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

button.change-name-btn {
  font-size: 14px;
  margin-right: 6px;
  margin-top: 2px;
  padding: 4px 8px;
  background-color: var(--green-team-color);
  border-radius: 4px;
}

@keyframes breathing-glow {
  0% {
    box-shadow: none;
  }
  50%,
  100% {
    box-shadow: 0 0 2px #effaff, 0 0 4px #effaff, 0 0 6px #effaff;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.highlight-box {
  padding: 6px 16px 18px 16px;
  background-color: antiquewhite;
  color: var(--font-color);
  text-align: left;
  margin: 20px auto 0 auto;
  max-width: 350px;
  border-radius: 4px;
}

.highlight-box ol {
  margin-top: 4px;
}

.highlight-box h4 {
  margin-top: 4px;
  margin-bottom: 8px;
}

.highlight-box h4 {
  text-align: center;
}

.manual li {
  font-size: 14px;
  list-style: disc;
  font-weight: lighter;
  margin-bottom: 8px;
}

em {
  display: flex;
  justify-content: center;
}

.home h1 {
  margin-bottom: 5px;
  margin-top: 20px;
  font-size: 20px;
}

.home h2 {
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 18px;
}

.home h3 {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .home {
    height: 100%;
  }

  .home-welcome {
    margin-top: 14px;
  }

  .highlight-box {
    margin-top: 8px;
  }

  .home h1 {
    font-size: 17px;
  }

  .home h2 {
    font-size: 15px;
  }

  .home h3 {
    margin-top: 12px;
  }

  .create-room-btn {
    font-size: 12px;
  }

  button.change-name-btn {
    font-size: 12px;
  }
}
